
.page-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    /* background-color: #fff; */
    display: block;
    z-index: 99999999;
}

.loader span {
	font-family: "Montserrat", Helvetica, sans-serif;
    position: absolute;
	font-weight: normal;
    top: 44%;
    font-size: 45px;
    letter-spacing: 15px;
    margin-left: -100px;
    left: 43%;
	margin-top: 20px;
    color: #212121;
}


/*Preloader 1*/

.loader-style-1 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 30px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: -webkit-transform 0.5s 0.1s;
    transition: transform 0.5s 0.1s;
    perspective: 9999px;
    color: #3e3e3e;
    margin: -40px 0 0 -40px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    margin-left: -37px;
    top: 65px;
    opacity: 1;
}

.loader-style-1.panelLoad {
    z-index: 11;
    top: 35%;
    margin-top: -60px;
    -webkit-animation: panel 2.2s infinite forwards;
    animation: panel 2.2s infinite forwards;
}

.loader-style-1.panelLoad .cube-face {
    color:white;
    box-shadow: inset 0 0 0 1px rgb(255, 255, 255), 0 0 1px 1px rgb(255, 255, 255);
}

.loader-style-1 .cube-face {
    width: inherit;
    height: inherit;
    position: absolute;
    background: #4e54c8;
    box-shadow: inset 0 0 0 1px #3e3e3e, 0 0 1px 1px #3e3e3e;
    opacity: 1;
    font-family: Arial, Helvetica, sans-serif;
}

.loader-style-1 .cube-face-front {
    transform: translate3d(0, 0, 40px);
    -webkit-transform: translate3d(0, 0, 40px);
    font-size: 30px;
}

.loader-style-1 .cube-face-back {
    -webkit-transform: rotateY(180deg) translate3d(0, 0, 40px);
    transform: rotateY(180deg) translate3d(0, 0, 40px);
}

.loader-style-1 .cube-face-left {
    -webkit-transform: rotateY(-90deg) translate3d(0, 0, 40px);
    transform: rotateY(-90deg) translate3d(0, 0, 40px);
}

.loader-style-1 .cube-face-right {
    -webkit-transform: rotateY(90deg) translate3d(0, 0, 40px);
    transform: rotateY(90deg) translate3d(0, 0, 40px);
}

.loader-style-1 .cube-face-top {
    -webkit-transform: rotateX(90deg) translate3d(0, 0, 40px);
    transform: rotateX(90deg) translate3d(0, 0, 40px);
}

.loader-style-1 .cube-face-bottom {
    -webkit-transform: rotateX(-90deg) translate3d(0, 0, 40px);
    transform: rotateX(-90deg) translate3d(0, 0, 40px);
}

@-webkit-keyframes panel {
    0% {
        -webkit-transform: rotateY(0deg) rotateZ(0deg);
    }
    20% {
        -webkit-transform: rotateY(90deg) rotateZ(0deg);
    }
    40% {
        -webkit-transform: rotateX(45deg) rotateZ(45deg);
    }
    60% {
        -webkit-transform: rotateX(90deg) rotateY(180deg) rotateX(90deg);
    }
    80% {
        -webkit-transform: rotateX(310deg) rotateZ(230deg);
    }
    100% {
        -webkit-transform: rotateX(360deg) rotateZ(360deg);
    }
}

@keyframes panel {
    0% {
        -webkit-transform: rotateY(0deg) rotateZ(0deg);
        transform: rotateY(0deg) rotateZ(0deg);
    }
    20% {
        -webkit-transform: rotateY(90deg) rotateZ(0deg);
        transform: rotateY(90deg) rotateZ(0deg);
    }
    40% {
        -webkit-transform: rotateX(45deg) rotateZ(45deg);
        transform: rotateX(45deg) rotateZ(45deg);
    }
    60% {
        -webkit-transform: rotateX(90deg) rotateY(180deg) rotateX(90deg);
        transform: rotateX(90deg) rotateY(180deg) rotateX(90deg);
    }
    80% {
        -webkit-transform: rotateX(310deg) rotateZ(230deg);
        transform: rotateX(310deg) rotateZ(230deg);
    }
    100% {
        -webkit-transform: rotateX(360deg) rotateZ(360deg);
        transform: rotateX(360deg) rotateZ(360deg);
    }
}


/*Preloader 2*/

.loader-style-2 {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -28px 0 -25px 0;
    border-bottom: 2px solid rgba(0, 0, 0, .1);
    border-left: 2px solid rgba(0, 0, 0, .1);
    border-right: 2px solid rgba(0, 0, 0, .1);
    border-top: 3px solid #2A94F6;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    -webkit-animation: spinn .6s infinite linear;
    animation: spinn .6s infinite linear;
}

@-webkit-keyframes spinn {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spinn {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

/* background css */

@import url('https://fonts.googleapis.com/css?family=Exo:400,700');

*{
    margin: 0px;
    padding: 0px;
}

body{
    font-family: 'Exo', sans-serif;
}

/* .blur{
  color: transparent;
  text-shadow: 0 0 10px white;
} */

.PgLoadParent{
    height: 120vh;
    background: #4e54c8; 
}

.context {
    width: 100%;
    position: absolute;
    top:0vh;
    height: 100%;
    
}

.context h1{
    text-align: center;
    color: #fff;
    font-size: 50px;
}


.area{
    background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
    background: #4e54c8;  
    width: 100%;
    height:100%;
    

}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}